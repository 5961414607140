@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    p {
        margin-bottom: 15px;
    }
    h2 {
        margin-bottom: 20px;
    }
}

.js-code-digit {
    border-width: 2px;
    font-size: 1.5rem !important;
}

.parent-item .children {
    display: none;
}

.active-menu-item.parent-item .children {
    display: block;
}

.btn {
    padding: 12px 20px;
    text-transform: uppercase;
    outline: none;
    text-decoration: none;
    font-style: normal;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #fff;
    line-height: 100%;
}

.btn-sm {

}

.btn-lg {
    padding: 15px 80px;
}

.btn-primary {
    color: #fff;
    background: #489fbf;
    border-color: #489fbf;
}

.btn-primary svg {
    display: inline !important;
    margin: -5px 10px -5px 0 !important;
}

.btn-primary:hover {
    background: #489;
    border-color: #489;
}

.btn-default-outline {
    background: #fff;
    color: #489fbf;
    border-color: #489fbf;
}

.btn-default-outline:hover {
    color: #489;
    border-color: #489;
}

.user-menu-btn {
    --tw-ring-opacity: 0 !important;
}

.logout-btn svg {
    display: none;
}

#search {
    height: 40px;
}

input:focus {
    --tw-ring-opacity: 0 !important;
}

.has-loader svg {
    display: inline-block;
}

.pull-right {
    float: right;
}

.modal-btn {
    display: inline-block;
    height: 42px !important;
    padding: 0 20px !important;
    line-height: 41px !important;
}

.logo {
    text-align: center;
}

.logo img {

}

.text-blue-500 {
    color: #2e5e72 !important;
}

.bg-blue-400 {
    background: #489fbf;
}

.border-blue-400 {
    border-color: #489fbf;
}

.bg-blue-400:hover {
    background: #489 !important;
}

.border-blue-400:hover {
    border-color: #489 !important;
}

.text-blue-400 {
    color: #489fbf;
}

.text-blue-400:hover {
    color: #489 !important;
}